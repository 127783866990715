<template>
<Panel header="Territory Mapping By Numbers of Beats">
    <div class="p-fluid">
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Name</label>
                <InputText v-model="forms.cluster_name" :class="{ 'p-invalid': errorAdd.cluster_name }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.cluster_name" >{{ errorAdd.cluster_name[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Number of Beat</label>
                <InputNumber  v-model="forms.cluster_n_value" mode="decimal" :min="0" :useGrouping="false" :class="{ 'p-invalid': errorAdd.cluster_n_value }"/>
                <small class="p-invalid" style="color: red" v-if="errorAdd.cluster_n_value" >{{ errorAdd.cluster_n_value[0] }}</small >
            </div>
        </div>
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Description</label>
                <Textarea v-model="forms.cluster_description" :autoResize="true" rows="5" cols="30" :class="{ 'p-invalid': errorAdd.cluster_description }"/>
                <small class="p-invalid" style="color: red" v-if="errorAdd.cluster_description" >{{ errorAdd.cluster_description[0] }}</small >
            </div>
        </div>
    </div>
    <Button :loading="loading" label="Save" icon="pi pi-save" class="p-button-primary" @click="saveNew" />
</Panel>
</template>


<script>

import { mapGetters } from "vuex";

export default {
    emits: ["submit"],
    props: ['item'],
    data() {
        return {
            // loading
            loading: false,

            // addNew
            forms: {
                cluster_name: null,
                cluster_n_value: 0,
                cluster_description: null,
            },
        }
    },
    mounted() {
        this.$store.commit('setErrorAdd', {});
    },
    computed:{
        ...mapGetters(['errorAdd']),
    },
    methods: {
        // ADDNEW
        saveNew() {
            this.loading = true;
            
            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/territory-mapping/create',
                data: {
                    "project_id": this.$route.params.id,
                    "clustertype_id": this.item.clustertype_id,
                    "cluster_name": this.forms.cluster_name,
                    "cluster_description": this.forms.cluster_description,
                    "cluster_n_value": this.forms.cluster_n_value,
                    "cluster_slot_no": this.item.clusterslot_id,
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Successfully Saved', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();

                    this.$store.dispatch("auth/getUserData");
                    
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorAdd', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Saved', life: 3000,});
            });
        },
        clearForms() {
            this.forms.cluster_name = null;
            this.forms.cluster_n_value = 0;
            this.forms.cluster_description = null;
        },
    }
}
</script>